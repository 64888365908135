import { compareAsc, compareDesc, parseISO } from 'date-fns';
import type { AIRunner, CacheUnit, Guardrails } from './types';

export function formatNumberWithCommas(
  number: number,
  locale: string = 'en-US',
  options?: Intl.NumberFormatOptions,
): string {
  return new Intl.NumberFormat(locale, options).format(number);
}

export function deepEqual(settings: AIRunner, lastUpdatedSettings: AIRunner): boolean {
  const settingsString = JSON.stringify(settings);
  const lastUpdatedSettingsString = JSON.stringify(lastUpdatedSettings);

  return settingsString === lastUpdatedSettingsString;
}

export function calculateCacheValue(cacheTtl: number, cacheTtlUnit: CacheUnit): number {
  // Define the conversion factors
  const conversionFactors: { [key in CacheUnit]: number } = {
    seconds: 1,
    minutes: 60,
    days: 86400, // 1 day = 86400 seconds
  };

  // Convert the cacheTtl to seconds
  const cacheValueInSeconds = cacheTtl * conversionFactors[cacheTtlUnit];

  return cacheValueInSeconds;
}

/**
 * Sorts an array of service objects based on the created or modified fields.
 *
 * @param data - Array of service objects to be sorted.
 * @param field - The field to sort by, either "created" or "modified".
 * @param order - The order of sorting, either "asc" (ascending) or "desc" (descending).
 * @returns The sorted array of service objects.
 */
export function sortRunnersByDate(
  data: AIRunner[],
  field: 'created' | 'modified',
  order: 'asc' | 'desc' = 'asc',
): AIRunner[] {
  return data.sort((a, b) => {
    const dateA = parseISO(a[field]);
    const dateB = parseISO(b[field]);

    return order === 'asc' ? compareAsc(dateA, dateB) : compareDesc(dateA, dateB);
  });
}

export function getGuardrailsCount({
  biasAndFairnessChecks,
  explainability,
  privacyProtections,
  contentModeration,
  ethicalAiFrameworks,
  securityProtections,
  regulatoryCompliance,
  shortResponses,
  technologyConcealment,
}: { [key in Guardrails]: boolean }): number {
  let count = 0;

  for (const value of Object.values({
    biasAndFairnessChecks,
    explainability,
    privacyProtections,
    contentModeration,
    ethicalAiFrameworks,
    securityProtections,
    regulatoryCompliance,
    shortResponses,
    technologyConcealment,
  })) {
    if (value) {
      count++;
    }
  }

  return count;
}

// Function to create the object with true/false values
export function createGuardrailsObject(selectedGuardrails: Guardrails[]): Record<Guardrails, boolean> {
  // All possible guardrails
  const allGuardrails: Guardrails[] = [
    'biasAndFairnessChecks',
    'explainability',
    'privacyProtections',
    'contentModeration',
    'ethicalAiFrameworks',
    'securityProtections',
    'regulatoryCompliance',
    'shortResponses',
    'technologyConcealment',
  ];

  // Create an object with all guardrails set to false initially
  const guardrailsObject: Record<Guardrails, boolean> = {} as Record<Guardrails, boolean>;

  // Populate the object with true/false based on the values array
  for (const guardrail of allGuardrails) {
    guardrailsObject[guardrail] = selectedGuardrails.includes(guardrail);
  }

  return guardrailsObject;
}
